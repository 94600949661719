import React from 'react';
import Link from 'next/link';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography, Container } from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10)
  },
  picture: {
    width: '100%',
    maxHeight: 240,
    my: { xs: 5, sm: 10 },
    margin: '80px 0'
  }

}));

// ----------------------------------------------------------------------

function Page404View() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          Sorry, page not found!
              </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          Sorry, we couldn’t find the page you’re looking for. Perhaps
          you’ve mistyped the URL? Be sure to check your spelling.
            </Typography>
        <img
          className={classes.picture}
          alt="404"
          src="/static/illustrations/illustration_404.svg"
        />

        <Link href="/" passHref>
          <Button
            to="/"
            size="large"
            variant="contained"
          >
            Go to Home
            </Button>
        </Link>
      </Box>
    </Container>
  );
}

export default Page404View;
